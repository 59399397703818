import React from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";

const topImage = require("../../assets/images/10-pages/workflow_automation/workflow_header.png");
export const icon1 = require("../../assets/images/10-pages/workflow_automation/workflow_sec_1_icon.png");
export const icon2 = require("../../assets/images/10-pages/workflow_automation/workflow_sec_2_icon.png");
export const icon3 = require("../../assets/images/10-pages/workflow_automation/workflow_sec_3_icon.png");
export const icon4 = require("../../assets/images/10-pages/workflow_automation/workflow_sec_4_icon.png");
export const icon5 = require("../../assets/images/10-pages/workflow_automation/workflow_sec_5_icon.png");

const section_one = require("../../assets/images/10-pages/workflow_automation/workflow_sec_1.png");
const section_two = require("../../assets/images/10-pages/usp_1/usp_sec_3.png");
const section_three = require("../../assets/images/10-pages/workflow_automation/workflow_sec_3.png");
const section_four = require("../../assets/images/10-pages/workflow_automation/workflow_sec_4.png");
const section_five = require("../../assets/images/10-pages/workflow_automation/workflow_sec_5.png");

const featureContent = [
  {
    icon: icon1,
    header: "Set it and forget it. Built to scale.  ",
    content:
      "Save hours of effort, and give back time to your HR and IT Teams with no-code, easy to use employee support automation built for your team.    ",
    link: null,
    image: section_one
  },
  {
    icon: icon2,
    header: "Workflows up in minutes. Just download.  ",
    content:
      "Prebuilt workflow automations help teams to hit the ground running in minutes. Use from our 400+ readily available prebuilt workflows for multiple HR and IT use cases from the marketplace.  ",
    image: section_two,
    url: "/conversational-ai-platform/marketplace",
    link: "Automations Marketplace"
  },
  {
    icon: icon3,
    header: "Intelligent workflows will enable remote working.   ",
    content:
      "Seamlessly integrate your existing apps to streamline and automate repetitive employee requests like <span> <a href='/use-cases/unlock-account-automation/'>unlock account</a>,</span> <span><a href='/use-cases/reset-password-automation/'>password resets,</a></span> <span><a href='/use-cases/user-provisioning-automation'>user provisioning,</a> </span> and workplace processes like <span><a href='/use-cases/employee-onboarding-automation'>user onboarding,</a></span> <span><a href='/use-cases/employee-offboarding-automation'>offboarding,</a></span> etc.    ",
    image: section_three
  },
  {
    icon: icon4,
    header: "Integrate, test, automate! ",
    content:
      "One-click integration with your existing applications is all it takes to create your desired workflows for auto-resolution of workplace support requests.    ",
    image: section_four,
    url: "/conversational-ai-platform/integrations",
    link: "All Integrations"
  },
  {
    icon: icon5,
    header: "Conversational Automation is the new normal.   ",
    content:
      "Make processes easier, faster, and conversational. Transform your workplace support with powerful workflow automation, driven by conversations. Increase your staff productivity.   ",
    image: section_five
  }
];

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Intelligent no-code workflow automation platform for workplace support | Workativ"
        description="Automate workplace support with intelligent workflow automation for IT and HR process. Connect with over 50+ Apps in a click."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={topImage}
        ogTitle="Intelligent no-code workflow automation platform for workplace support | Workativ"
        ogDescription="Automate workplace support with intelligent workflow automation for IT and HR process. Connect with over 50+ Apps in a click."
      />
      <Container>
        <section className="workflow_automation_wrapper">
          <Layout
            backgroundColor={"bg_header_workplace_support"}
            logoFor="ASSISTANT"
          >
            <RegisterSection
              rightImage={topImage}
              altImage={"Deliver your workflow automations in a jiff"}
              backgroundColor={"bg_header_workplace_support"}
            >
              <RegisterSection.FormWithContent>
                <RegisterSection.MainHeader>
                  Deliver your workflow automations in a jiff.
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                  Automating workflows, made easy for you. Trust your team to
                  design and create automated workflows. No coding and no
                  developer needed.
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>{" "}
            {featureContent.map((feature, index) => {
              if (index % 2 !== 0) {
                return (
                  <div className="support_chatbot">
                    <RightImageWithContentFeature
                      image={feature.image}
                      altImage={feature.header}
                    >
                      <RightImageWithContentFeature.Header>
                        <div className="icon-top-img">
                          <img src={feature.icon} alt="icon" />{" "}
                        </div>
                        <h3>{feature.header}</h3>
                      </RightImageWithContentFeature.Header>

                      <RightImageWithContentFeature.SubHeader>
                        <p
                          dangerouslySetInnerHTML={{ __html: feature.content }}
                        />
                      </RightImageWithContentFeature.SubHeader>
                      <RightImageWithContentFeature.Link>
                        {feature.link && (
                          <div className="card_link_landing">
                            <a href={feature.url} className="url_manipulation">
                              {feature.link}
                              <span className="arrow_svg_link">
                                <ArrowRightICon />
                              </span>
                            </a>
                          </div>
                        )}
                      </RightImageWithContentFeature.Link>
                    </RightImageWithContentFeature>
                  </div>
                );
              }
              return (
                <LeftImageWithContent
                  image={feature.image}
                  altImage={feature.header}
                >
                  <LeftImageWithContent.HeaderIcon>
                    <div className="icon-top-img">
                      <img src={feature.icon} alt="icon" />{" "}
                    </div>
                  </LeftImageWithContent.HeaderIcon>

                  <LeftImageWithContent.MainHeader>
                    {feature.header}
                  </LeftImageWithContent.MainHeader>
                  <LeftImageWithContent.SubHeader>
                    <p dangerouslySetInnerHTML={{ __html: feature.content }} />
                  </LeftImageWithContent.SubHeader>
                  <LeftImageWithContent.Link>
                    {feature.link && (
                      <div className="card_link_landing">
                        <a href={feature.url} className="url_manipulation">
                          {feature.link}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      </div>
                    )}
                  </LeftImageWithContent.Link>
                </LeftImageWithContent>
              );
            })}
            <RequestForm isFooterForm={true} />
          </Layout>
        </section>
      </Container>
    </>
  );
}
